<template>
  <div class="row">
    <div class="col-12 col-md-6 my-10 my-md-0 mb-md-30" v-for="product of productsList" :key="product.id" :class="categoryColumnsClass()"
      v-remember-scroll-cache="{ itemsName: 'products', selector: 'a' }">
      <ProductCard :product="product" :optionHeight="getOptionHeight" />
    </div>
  </div>
</template>

<script>
import ProductCard from "@/esf_kerkrade_vitanatura/core/components/ProductCard";
export default {
  name: "ProductsSection",
  components: { ProductCard },
  props: {
    productsList: {
      type: Array,
      require: true,
    },
  },
  computed: {
    getOptionHeight() {
      this.productsList.forEach((p) => {
        if (p.configurable_options) {
          p.configurable_options.find((co) => {
            if (co.values) {
              if (co.values.length > this.maxCount) {
                this.maxCount = co.values.length;
              }
            }
          });
        }
      });
      let returnValue = Math.floor(this.maxCount / 4, 0) + 1;
      return returnValue;
    },
  },
  methods: {
    categoryColumnsClass() {
      let columns = this.$store.state.category_theme.categoryColumns
      if (columns == 2) {
        return 'col-xl-6'
      } else if (columns == 3) {
        return "col-xl-4"
      } else if (columns == 4) {
        return "col-xl-3"
      }
    },
  },
  data() {
    return {
      maxCount: 0,
    };
  },
};
</script>