<template>
    <div class="filtered-products">
      <div class="loader-box" v-if="isLoading">
        <div class="reverse-spinner"></div>
      </div>
      <div v-else-if="totalProducts === 0">NO Products Found</div>
      <div v-else>
        <ProductsSection :products-list="productList" />
      </div>
    </div>
  </template>
  
  <script>
  import ProductsSection from "@/esf_kerkrade_vitanatura/core/components/ProductsSection";
  import chunkArray from "@/base/helpers/chunkArray";
  
  export default {
    name: "FilteredProducts",
    components: {
      ProductsSection,
    },
    computed: {
      isLoading() {
        return this.$store.getters["category/getLoadProducts"];
      },
      productList() {
        return this.$store.getters["category/getShowProducts"];
      },
      totalProducts() {
        return this.$store.getters["category/getTotalProducts"];
      },
      productsChunks() {
        return chunkArray(this.$store.getters["category/getShowProducts"], 12);
      },
    },
    methods: {
      isAlive() {
       setTimeout(() => {
          if(this.isLoading){
            window.location.reload();
          }
        }, 3000);
      }
    },
    mounted() {
      this.isAlive();
    },
  };
  </script>
  
  
  