<template>
    <div class="product-listing">
      <AdvertisingBanner class="col-12 my-30 mt-lg-0" />  
      <CategoryPageContent :category="category" />
      <section class="bottom-txt">
        <div v-html="category.description"></div>
      </section>
    </div>
  </template>
  
  <script>
  import CategoryPageContent from "@/esf_kerkrade_vitanatura/core/components/CategoryPageContent";
  import AdvertisingBanner from "@/base/core/components/AdvertisingBanner";
  export default {
    name: "ProductListingV2",
    components: {
      CategoryPageContent,
      AdvertisingBanner,
    },
    computed: {
      category() {
        return this.$store.getters["category/getCurrentCategory"];
      },
    },
  };
  </script>
  
  